
/**
PRODUCT ZOOM
*/
var main_productVisu = null;

function zoomImg(slide) {

    $('#zoom_box_wrapper').css('opacity', 0);


    window.setTimeout(function () {

          /**
         * Init zoom thumbs swiper
         *
         *
         */

        var zoomProduct = $(".productLightboxVisu");
        var zoomThumbs = $("#thumbs_scroller");

        if ($("#thumbs_scroller .swiper-slide").length > 1) {
            zoomThumbs = new Swiper('#thumbs_scroller', {
                slidesPerView: 'auto',
                spaceBetween: 24,
                direction: "vertical",
                loop: false,
                watchSlidesVisibility: true,
                navigation: {
                    nextEl: '.zoomThumbsNext',
                    prevEl: '.zoomThumbsPrev',
                },
                threshold: 4,
            });

        } else {
            $("#thumbs_scroller, .thumbs_wrapper").addClass('no_swiper')
        }

        /**
         * Init zoom main swiper
         */
        if ($("#zoom_box_wrapper .swiper-slide").length > 1) {
             zoomProduct = new Swiper('.productLightboxVisu', {
                slidesPerView: 1,
                mousewheel: false,
                thumbs: {
                    swiper: zoomThumbs
                },
                loop: false,
                navigation: {
                    nextEl: ".zoomProdNext",
                    prevEl: ".zoomProdPrev",
                },
            });
        } else {
            $("#zoom_box_wrapper").addClass('no_swiper')
        }

        if (slide == null) {
            if (typeof(productMainVisu) != 'undefined') {
                zoomProduct.slideTo(productMainVisu.activeIndex);
            }
        } else {
            if ($('#thumbs_scroller .video_holder').length > 0) {
                zoomProduct.slideTo(slide);
            } else {
                zoomProduct.slideTo(slide - 1);
            }
        }

        zoomProduct.on('slideChange',() => {
            main_productVisu.slideTo(zoomProduct.activeIndex);
        });

    }, 50);

    window.setTimeout(function () {
        $('#zoom_box_wrapper').css('opacity', 1);
    }, 350);

    openMultiShad('zoomBox');
    $("#shad_abox").addClass('actif white');
    $('body.product_page').css('position','fixed');
}


$(function () {
    $('#shad_abox').on("click", function() {
        closeMultiShad();
        $('.lightbox.actif').removeClass('actif');
    });


    if($("#jqzoom .swiper-slide").length > 1) {

        //animation to avoid slide's width transition on pageload
        $('#jqzoom, #visual_scroller_wrapper').animate({
            opacity: 1,
        },10);

        main_productVisu = $("#jqzoom");
        var productThumbs = $("#visual_scroller");

        // PRODUCT SWIPER
        if($("#visual_scroller .swiper-slide").length > 2) {
            productThumbs = new Swiper('#visual_scroller', {
                slidesPerView: 4,
                spaceBetween: 40,
                direction: 'vertical',
                loop: false,
                navigation: {
                    nextEl: '#visual_scroller_wrapper .thumbNext',
                    prevEl: '#visual_scroller_wrapper .thumbPrev',
                },
                breakpoints: {
                    640: {
                        slidesPerView: 1.25,
                        spaceBetween: 10,
                        centeredSlides: true,
                    },
                    1160: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    1420: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                        centeredSlides: false,
                    },
                    1680: {
                        slidesPerView: 4,
                        spaceBetween: 40,
                        centeredSlides: false,
                    },
                }
            });
            
        } else {
            $("#visual_scroller_wrapper").addClass('no_swiper');
        }

        main_productVisu = new Swiper('#jqzoom', {
            loadOnTransitionStart: true,
            rewind: true,
            thumbs: {
                swiper: productThumbs
            },
            pagination: {
                el: '#jqzoom .swiper-pagination',
                type: "bullets",
                clickable: true
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            slidesPerView: 1,
            loop: false,
        });

        main_productVisu.on('slideChange',() => {
            productThumbs.slideTo(main_productVisu.activeIndex);
        });

        if ($('#jqzoom .swiper-slide').hasClass('has_video')) {
            $('#jqzoom + .swiper-pagination').addClass('outarea');
            $('.product_page .page_produit .wrapper_product_detail').css('padding','25px');
        }
    }

    if ($("body").hasClass("product_page")) {
        resetThumbSwiperFP();

        $(window).on('resize', function () {
            resetThumbSwiperFP();
        });

    }

    if ($('body.product_page').length && $('#bundle_caracs').length) {
        $('#bundle_caracs .title_container').on('click touch', function() {
            $(this).siblings('.bundle_caracs_container.collapsible').slideToggle();
            $(this).toggleClass('active');
        });
    }
});


function showsendfriendmodal(product_id, path_web, type) {
    var elname = type === "look_basket" ? "modbox" : "sendfriendbox";
    var actualposition = document.documentElement.scrollTop;

    if (type !== 'look_basket') {
        var psnok = document.getElementById('product_sendfriend_nok');
        var psfok = document.getElementById('product_sendfriend_ok');
        if (psnok) {
            psnok.innerHTML = '';
            psnok.style.display = 'none';
        }
        if (psfok) {
            psfok.innerHTML = '';
            psfok.style.display = 'none';
        }

        prodGetProductDisplay(product_id, path_web, type);

        openMultiShad("sendfriendbox");
        $("#shad_abox").addClass('actif');

    } else {
        show_lightbox(elname);
        $(".loader", "#bloc_add_basket").hide();
    }
}

function prodGetProductDisplay(id, path_web, type) {
    if (typeof type == "undefined") {
        var response = ajax_file(
            path_web + "ajax_get_infos_send_friend.php?product_id=" + id
        );
        document.getElementById("sendfriend_prodinfos").innerHTML = response;
    } else {
        if (type == "look") {
            $("#titre_popup").html(Translator.translate('sendfriend_title_look'));
            $("#friend_form").show();
            $("#btn_popup_panier").hide();

            $("#bloc_btn_look").show();
            $("#bloc_btn_loader_look").hide();
        } else if (type == "look_basket") {
            $("#titre_popup").html("Vous venez d'ajouter le look suivant");
            $("#friend_form").hide();
            $("#btn_popup_panier").show();

            $("#bloc_btn_look").show();
            $("#bloc_btn_loader_look").hide();
        } else {
            $("#titre_popup").html("Vous venez d'ajouter le produit suivant");
            $("#friend_form").hide();
            $("#btn_popup_panier").show();
        }
    }

    if (
        navigator.userAgent.indexOf("MSIE") != -1 &&
        navigator.userAgent.indexOf("6.") != -1
    ) {
        var svn = document.getElementsByTagName("SELECT");
        for (a = 0; a < svn.length; a++) {
            svn[a].style.visibility = "hidden";
        }
    }
}

/**
    * Dans la fiche produit affiche un champs email pour que l'ont previenne que le produit est disponible
*/
function lightboxAlertStock() {

    var $taille_id = $("#tailleProd").val(),
        $couleur_id = $("#couleurProd").val();

    if ($taille_id == '') {
        if (!$('#sizeerror').is(':visible')) {
            $('#sizeerror').slideDown();
        }
        return;
    } else if ($couleur_id == '') {
        alert(Translator.translate('js_error_couleur'));
        return;
    }

    var isFormVisible = $('.bloc_add_alert_form').is(':visible');
    var isMessageVisible = $('.bloc_add_alert_confirmation').is(':visible');
    var isErrorVisible = $('.bloc_add_alert_erreur').is(':visible');

    if (isFormVisible || isMessageVisible || isErrorVisible) {

        if (isFormVisible) {

            $('.bloc_add_alert_form').slideUp('fast');
        }

        if (isMessageVisible) {

            $('.bloc_add_alert_confirmation').slideUp('fast');
        }

        if (isErrorVisible) {

            $('.bloc_add_alert_erreur').slideUp('fast');
        }
    } else {
        $('#btn_add_alert').hide();
        $('.bloc_add_alert_form').slideDown('fast');
    }
}

$('#bloc_add_alert #btn_add_alert').click(function(){
    $(this).hide();
});

$('.bloc_add_alert_confirmation button').click(function(){
    $('#bloc_add_alert #btn_add_alert').show();
});

/* DESCRIPTION */

$(document).ready(function(){
    $('.thumbslide .see_more').on('click', function(e) {
        $('.thumbslide').removeClass('active');
        $(this).parents('.thumbslide').addClass('active');
    });
});

function resetThumbSwiperFP() {
    var swiper_FP_main_push_height = $("#jqzoom").height();
    var swiper_FP_main_push_width = $("#jqzoom").width();
    $("#jqzoom").height(swiper_FP_main_push_width);
    $("#visual_scroller_wrapper").height(swiper_FP_main_push_width - (2*60));
    $("#visual_scroller_wrapper").addClass('is-active');

}
/*******************************************************************************************************/
/* CUSTOM OUTSIDE DOM SCRIPTS
/*******************************************************************************************************/


/*******************************************************************************************************/
/* CUSTOM DOM READY SCRIPTS
/*******************************************************************************************************/

// Please be careful to add your custom scripts to this call and don't create too much $(function(){}) or document.ready()
$(function() {

    // WP-23906 prevent hover mechanism on french-days entry
    if ($('#menu_french-days').length) {
        $('#menu_french-days').parent('.main_menu_itm').addClass('itm_no_rollhover');
        $('#menu_french-days').parent('.main_menu_itm').attr('onmouseenter', '');
        $('#menu_french-days').parent('.main_menu_itm').attr('onmouseleave', '');
    }

    // Init preheader promo_swiper
    if ($('.promo_swiper').length && ($('.promo_swiper .swiper-slide').length > 1)) {
        new Swiper('.promo_swiper', {
            slidesPerView: 1,
            autoplay: true,
            speed: 1000,
            loop: true
        })
    }

    if ($('#content_pdvBox .content_store_locator').length) {
        $('#content_pdvBox .content_store_locator').overlayScrollbars({});
    }

    /* [WP-24351] - Bundle overlay Scroll */
    if ($('body.product_page #batchPanel').length) {
        $('body.product_page #batchPanel .contentTxt').overlayScrollbars({});
    }

    /* [WP-27936] - Bundle caracteristiques overlay Scroll */
    if ($('body.product_page #bundle_caracs').length) {
        $('body.product_page #bundle_caracs .contentTxt').overlayScrollbars({});
    }

    /********** HOMEPAGE **********/
    if ($('#sliderCoverModule .swiper-container .swiper-slide').length > 1) {
        var coverHomeSwiper = new Swiper('#sliderCoverModule .swiper-container', {
            preloadImages: false,
            lazy: true,
            loop: true,
            speed: 1500,
            autoplay: {
                delay: 3000,
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
            },
        });

        //Autostop on hovering
        $("#sliderCoverModule .swiper-container").hover(function() {
            (this).swiper.autoplay.stop();
        }, function() {
            (this).swiper.autoplay.start();
        });
    }

    $('.gondole_swiper.swiperTg').each(function() {
        if ($(this).find('.swiper-slide').length > 1) {
            var gondoleHomeSwiper = new Swiper($(this)[0], {
                preloadImages: false,
                slidesPerView: 5,
                slidesPerGroup : 5,
                spaceBetween: 10,
                pagination: {
                    el: $(this).parent().find('.swiper-container + .swiper-pagination')[0],
                    type: 'progressbar',
                },
                navigation: {
                    nextEl: $(this).parent().find('.swiper-button-next')[0],
                    prevEl: $(this).parent().find('.swiper-button-prev')[0],
                },
                breakpoints: {
                    1280: {
                        slidesPerView: 5,
                    }
                }
            });
        }
    });

    $('.tete_de_gondole_par_5').each(function(e) {
        var id_tete_de_gondole_par_5 = $(this).attr('id');

        if ($(this).find('.swiper-slide').length > 1) {
            var tete_de_gondole_par_5 = new Swiper('#' + id_tete_de_gondole_par_5, {
                preloadImages: false,
                slidesPerView: 3,
                slidesPerGroup : 1,
                spaceBetween: 10,
                pagination: {
                    el: '#' + id_tete_de_gondole_par_5 + ' + .tete_de_gondole_nav .swiper-pagination',
                    type: 'progressbar',
                },
                navigation: {
                    nextEl: '#' + id_tete_de_gondole_par_5 + ' + .tete_de_gondole_nav .tete_de_gondole-button-next',
                    prevEl: '#' + id_tete_de_gondole_par_5 + ' + .tete_de_gondole_nav .tete_de_gondole-button-prev',
                },
                breakpoints: {
                    1120: {
                        slidesPerView: 5,
                    }
                }
            });
        }
    });

    if ($('.tete_de_gondole_no_nav .swiper-slide').length > 1) {
        $('.tete_de_gondole_no_nav').each(function(e) {
            var id_tete_de_gondole_no_nav = $(this).attr('id');

            var tete_de_gondole_no_nav = new Swiper('#' + id_tete_de_gondole_no_nav, {
                preloadImages: false,
                slidesPerView: 3,
                slidesPerGroup : 1,
                spaceBetween: 10,
                breakpoints: {
                    1120: {
                        slidesPerView: 5,
                    }
                }
            });
        });
    }

    if ($('.tete_de_gondole_grid_4 .swiper-slide').length > 1) {
        var gondoleHomeSwiper = new Swiper('.tete_de_gondole_grid_4', {
            slidesPerView: 2,
            grid: {
                rows: 2,
            },
            spaceBetween: 30,
        });
    }

    initAchatExpressSwipers();

    /********** PRODUCT DETAILS PAGE **********/
    // Check direct swiper-slides (because there are also swiper-slides in achat express)
    if($("#gondole_complete .swiper-wrapper>.swiper-slide").length > 1) {

        if ($('.similar_product_pagi').length && !$('.similar_product_pagi').is(':visible')) {
            $('.similar_product_pagi').show();
        }
        var productThumbsa = new Swiper('#gondole_complete', {
            loop: false,
            slidesPerView: 4,
            slidesPerGroup: 4,
            spaceBetween: 10,

            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });
    } else {
        $("#gondole_complete").addClass('no_swiper');

        if ($('.similar_product_pagi').length) {
            $('.similar_product_pagi').hide();
        }
    }

    // Check direct swiper-slides (because there are also swiper-slides in achat express)
    if($("#gondole_aimerez .swiper-wrapper>.swiper-slide").length > 1) {

        if ($('.complementary_product_pagi').length && !$('.complementary_product_pagi').is(':visible')) {
            $('.complementary_product_pagi').show();
        }
        var productThumbsaa = new Swiper('#gondole_aimerez', {
            loop: false,
            slidesPerView: 4,
            slidesPerGroup: 4,
            spaceBetween: 10,

            navigation: {
                nextEl: '.swiper-button-nexta',
                prevEl: '.swiper-button-preva',
            },
        });
    } else {

        $("#gondole_aimerez").addClass('no_swiper');

        if ($('.complementary_product_pagi').length) {
            $('.complementary_product_pagi').hide();
        }
    }

    if (($('#dimension_carac').length) > 0) {
        window.setTimeout(function() {
            $('#dimension_carac .wrapping').overlayScrollbars({});
        }, 1000);
    }

    $('#productVisitedSwiper').each(function() {
        if($("#productVisitedSwiper .swiper-slide").length > 1) {
            var productThumbsaa = new Swiper('#productVisitedSwiper', {
                spaceBetween: 5,
                loop: false,
                slidesPerView: 5,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                scrollbar: {
                    el: '.swiper-scrollbar',
                    draggable: true,
                },
                pagination: {
                    el: '.swiper-pagination',
                    type: 'progressbar',
                },
            });
        } else {
            $("#productVisitedSwiper").addClass('no_swiper');
        }

        productThumbsaa.on('init', function() {
            var isWindowLarge = $(window).outerWidth() > 1300;
            var swiperSlideLength = $('#algolia_visited_articles #productVisitedSwiper .swiper-slide').length;
            var swiperComponents = '#algolia_visited_articles .swiper-button-next, #algolia_visited_articles .swiper-button-prev, #algolia_visited_articles .swiper-scrollbar, #algolia_visited_articles .swiper-pagination';
        
            isWindowLarge && swiperSlideLength < 5 ? $(swiperComponents).addClass('cache') : $(swiperComponents).removeClass('cache');
        });
    });
    
    /********** TUNNEL LIGHTBOX PRODUCT OFFERT **********/

    if ($(".js-form-selection-offer .color_list.swiper-container:visible").length) {
        $(".js-form-selection-offer .color_list.swiper-container").each(function( index ){
            if ($(this).find(".form_itm").length >= 4) {
                var sliderColorOfferProduct = new Swiper('.color_list', {
                    spaceBetween: 5,
                    loop: true,
                    slidesPerView:'auto',
                    navigation: {
                        nextEl: '.nextColour',
                        prevEl: '.prevColour',
                    },
                });
            }
        });
    }

    // HEADER SCROLLBARS
    if ($('#wishlist_list').length) {
        $('#wishlist_list').overlayScrollbars({
            scrollbars: {
                autoHide: "leave",
                autoHideDelay: 0,
            }
        });
    }

    if ($('#order_basket_list').length) {
        $('#order_basket_list').overlayScrollbars({
            scrollbars: {
                autoHide: "leave",
                autoHideDelay: 0,
            }
        });
    }

    //CMS SWIPER
    if ($("#articles_list_swiper").length) {
        cnt = 0;
        $("#articles_list_swiper.swiper-container").each(function(e){
            cnt++;
            $(this).addClass('article-swiper-init-'+ cnt);
            if ($(this).find('.swiper-slide').length > 1) {
                var articleCms = new Swiper('.article-swiper-init-'+ cnt, {
                    spaceBetween: 50,
                    slidesPerView: 3,
                    pagination: {
                        el: '.swiper-pagination',
                        type: 'progressbar',
                    },
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                });
            }
        })
    }

    //Signup module - change mastered trad
    if ($('.signup-module .w-input.w-email-input .w-input-label span').length) {
        $('.signup-module .w-input.w-email-input .w-input-label span').text(Translator.translate('selection_mail'));
        $('.signup-module .w-input.w-email-input .w-input-label span').addClass('visible');
    }

    if ($('body').hasClass('step_2')) {
        window.setTimeout(function() {
            $('.sidebar-right').overlayScrollbars({});
            $('.gm-style-iw-d').overlayScrollbars({});
        }, 1000);
    }    

    /********* Wordings switches *********/
    /* Tunnel - Track */
    if ($('.tunnel_track_pusher').length) {
        var cartTrackText = Translator.translate('cart_tunnel_track');
        var loginTrackText = Translator.translate('login_tunnel_track');

        $('.tunnel_track_pusher #step-01 span').html(cartTrackText);
        $('.tunnel_track_pusher #step-02 span').html(loginTrackText);
    }

    /* BACK TO TOP FUNCTION */
    var stickyNavEl = $('#rayon_sticky_nav');
    var backToTopBtn = $('#back_to_top');

    if (stickyNavEl.length > 0) {
        var stickyNavTop = stickyNavEl.offset().top;
    }

    function stickyNav() {
        if (stickyNavEl.length > 0) {
            var scrollTop = $(window).scrollTop(); // our current vertical position from the top

            if (scrollTop > stickyNavTop) {
                stickyNavEl.addClass('sticky');
                $("#cart_top").appendTo(".filters_left");

            } else {
                stickyNavEl.removeClass('sticky');
                $("#cart_top").appendTo(".rightSide");
            }
        }
    };

    stickyNav();

    $(window).scroll(function () {
        stickyNav();

        if ($(window).scrollTop() > 300) {
            backToTopBtn.addClass('show');
        } else {
            backToTopBtn.removeClass('show');
        }
    });

    backToTopBtn.on('click', function (e) {
        e.preventDefault();
        $('html, body').animate({ scrollTop: 0 }, 500, 'linear');
    });

    // Bloc SEO max 900 texte
    if ($('.wrapper_bandeau.bandoBottom').length) {
        var text_element = $('.txt_content');
        var max_characters = 900;

        if(text_element.length > 0) {
            text_element.each(function () {
                var text = $(this).html();

                if(text.length > max_characters) {
                    var summary = build_text_summary($(this), max_characters);

                    //load summary + read more
                    $(this).css('display','none');
                    $('<div class="rayon_desc_summary">' + summary + ' <a class="see_more_txt">' + Translator.translate('read_more') + '</a></div>').insertBefore($(this));
                }
            });

            $(document).on('click', '.see_more_txt', function () {
                $(this).parent().hide();
                $(this).parent().next().show().fadeIn(400).slideDown(400);
            });
        }
    }

    // Init tgHomeSwiper page adv
    $('.gondole_swiper.page-adv').each(function () {
        if ($(this).find('.swiper-slide').length > 1) {
            var tgHomeSwiper = new Swiper($(this)[0], {
                preloadImages: false,
                slidesPerView: 5,
                slidesPerGroup : 5,
                spaceBetween: 10,
                pagination: {
                    el:  $(this).parent().find('.swiper-container + .swiper-pagination')[0],
                    type: 'progressbar',
                },
                navigation: {
                    nextEl: $(this).parent().find('.swiper-button-next')[0],
                    prevEl: $(this).parent().find('.swiper-button-prev')[0],
                },
                breakpoints: {
                    1280: {
                        slidesPerView: 5,
                    },
                    1024: {
                        slidesPerView: 4,
                    }
                }
            });
        }
    });

    // CMS module (image + text + list) swiper with 6 products per slide
    if ($('#swiperImageTextList .swiper-slide').length > 0) {
        var swiperImageTextList = new Swiper("#swiperImageTextList", {
            slidesPerView: 1, // Display 1 slide per view
            spaceBetween: 30,
            roundLengths: true,
            pagination: {
                el: '#swiperImageTextList > .swiper-pagination',
                type: 'progressbar',
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });
    }

    // FLOWBOX
    setTimeout(function() {
        /* Initiate FLOWBOX */
        /* Add title and button to FlOWBOX on homepage and product page */
        if($('body.homepage').length > 0 || $('body.product_page').length > 0) {
            /* Check repetitively for the existence of #flowbox-container every 300ms */
            let intervalID = setInterval(addTitleToFlowbox, 300);
            let counter = 0;
            const maxRepetitions = 17;

            function addTitleToFlowbox() {
                // Check if #flowbox-container exists and is not empty
                if ($('#flowbox-container').length > 0 && !$('#flowbox-container').is(':empty')) {
                    let createDivFlowboxTitle = '<div id="banner_flowbox_title"><div class="ctn"><p class="banner_h1">' + Translator.translate('banner_flowbox_title') + '</p><p class="banner_h2">' + Translator.translate('banner_flowbox_subtitle') + '</p></div></div>';
                    let btnFlowbox = '<a href="' + path_relative_root + create_link('inspiration_flowbox') + '" class="button primary flowboxBtn"><span>' + Translator.translate('flowbox_inspi_button') + '</span></a>';

                    $(createDivFlowboxTitle).insertBefore("#flowbox-container");
                    $(btnFlowbox).insertAfter("#flowbox-container");

                    /* #flowbox-container found and not empty, stop repetitions */
                    clearInterval(intervalID);
                } else if (counter >= maxRepetitions) {
                    /* Stop repetitions after 17 times */
                    clearInterval(intervalID);
                }

                ++counter;
            }
        }

        /* Initiate FLOWBOX and Create CTA (filter by tags) only on page inspiration.php */
        if ($('body.inspiration_flowbox').length) {
            /* Create Flowbox container */
            let flowboxContainer = '<div id="inspiration-flowbox-container"></div>';
            $("#copyright").before(flowboxContainer);

            /* Create Title */
            let createDivFlowboxTitle = '<div id="banner_flowbox_title"><div class="ctn"><p class="banner_h1">' + Translator.translate('banner_flowbox_title_desktop') + '</p><p class="banner_h2">' + Translator.translate('banner_flowbox_subtitle') + '</p></div></div>';
            let btnFlowbox = '<a href="' + path_relative_root + create_link('inspiration_flowbox') + '" class="button primary flowboxBtn"><span>' + Translator.translate('flowbox_inspi_button') + '</span></a>';

            $(createDivFlowboxTitle).insertBefore("#inspiration-flowbox-container");
            $(btnFlowbox).insertAfter("#inspiration-flowbox-container");

            /*
                Object of CTA msgid and respective tag, this object will render the CTAs.
                To modify CTA content, modify the msgstr of these msgid in .po files.
                To modify tag used to filter, modify the value directly in the object below.
            */
            let cta_name_tag = {
                'cta_rangement': 'rangement',
                'cta_cuisine': 'cuisine',
                'cta_salle_de_bain': 'salledebain',
                'cta_dressing': 'dressing',
                'cta_buanderie': 'buanderie',
                'cta_all':''
            };

            /* Create wrapper for the CTAs */
            let flowboxCtaWrapper = '<div id="banner_flowbox_cta"></div>';
            $(flowboxCtaWrapper).insertBefore('#inspiration-flowbox-container');

            /* Loop over cta_name_tag object to create CTA and append them to the wrapper */
            $.each(cta_name_tag, function(name, tag) {
                let button = '<button id="'+ name +'" class="flowbox_btn_cta" data-tag="' + tag + '"><span class="cta_name">' + Translator.translate(name) + '</span></button>';
                $('#banner_flowbox_cta').append( button );
            });

            /* Create loader */
            let loader = '<div id="flowbox_loader"></div>';
            $('#banner_flowbox_cta').append(loader);

            /* Add class 'active' to the 'ALL' CTA */
            $('.flowbox_btn_cta:first-child').addClass('active');

            /* Initialise Flowbox */
            let flowboxLocale = Translator.translate('flowbox_locale');

            window.flowbox('init', {
                container: '#inspiration-flowbox-container',
                key: 'wOA1JnzuTTaEAtWxHMADeA', /* 5FIVE Flowkey Page Communauté */
                locale: flowboxLocale, /* language-COUNTRY */
                tags: [],
                tagsOperator: "any"
            });

            /* Function to test if FlowBox has initiated and hide loader */
            function hideLoader() {
                setTimeout( function () {
                    if ($('.bzfy-o-layout__item').length) {
                        $('#flowbox_loader').fadeOut(500);
                        return false;
                    }

                    setTimeout(function() {
                        if ($('.bzfy-o-layout__item').length) {
                            $('#flowbox_loader').fadeOut(500);
                            return false;
                        }

                        setTimeout(function() {
                            if ($('.bzfy-o-layout__item').length) {
                                $('#flowbox_loader').fadeOut(500);
                                return false;
                            }

                            setTimeout(function() {
                                $('#flowbox_loader').fadeOut(500);
                            }, 1500);
                        }, 500);
                    }, 500);
                }, 500);
            };

            /* Hide initial loader */
            hideLoader();

            /* Function to update Flowbox on click of CTA */
            function updateFlow (tag, tagsOperator) {
                window.flowbox('update', {
                    key: 'wOA1JnzuTTaEAtWxHMADeA', /* 5FIVE Flowkey Page Communauté */
                    tags: [tag],
                    tagsOperator: tagsOperator
                });
            };

            /* CTA event listener */
            $('button.flowbox_btn_cta').on('click', function() {
                $('#flowbox_loader').fadeIn(500);

                if (this.dataset.tag == "") {
                    /* Reset FlowBox */
                    updateFlow([], "any");
                } else {
                    /* Update Flowbox with selected tag */
                    updateFlow(this.dataset.tag, "all");
                }

                /* Change which CTA has class 'active' for future styling purposes */
                $('button.flowbox_btn_cta').removeClass('active');
                $(this).addClass('active');

                /* Hide loader */
                hideLoader();
            });
        }
    }, 300);

    // Init swiper categorie page adv
    $('body.cms_page_new .swiperCategories').each(function () {
        if ($(this).find('.swiper-slide').length > 1) {
            swiperCategories = new Swiper('.swiperCategories', {
                preloadImages: false,
                slidesPerView: 5.54,
                slidesPerGroup : 5,
                spaceBetween: 19,
                loop: false,
                navigation: {
                    nextEl: '.swiperCategories .swiper-button-next',
                    prevEl: '.swiperCategories .swiper-button-prev',
                }
            });
        } else {
            $(this).addClass("no-arrow");
        }

        if ($(this).find('.swiper-slide').length <= 5) {
            $(this).addClass('center_slides');
        } else {
            $(this).removeClass('center_slides');
        }
    });

    /* ADD BUTON VOIR PLUS AFTER flowbox-container */
    var flowboxBtn = "<div id='flowboxbtn_voirplus'><a class='cta-btn' href='" + path_relative_root + create_link('inspiration_flowbox') + "'>" + Translator.translate('see_more_btn') + "</a></div>";
    setTimeout(() => {
        if ($("body.homepage #flowbox-container")) {
            $(flowboxBtn).insertAfter("body.homepage #flowbox-container");
        }
    }, 500);

    if ($("body.product_page #content_pdvBox_new").length) {
        var el = $("#content_pdvBox_new .formulaire .w-contact-form");

        el.find("#clientNom").blur(function () {
            if (!validationContactMagFormCompulsory (el.find("#clientNom"))) {
                return false;
            }
        });

        el.find("#clientPrenom").blur(function () {
            if (!validationContactMagFormCompulsory (el.find("#clientPrenom"))) {
                return false;
            }
        });

        el.find("#clientMail").blur(function() {
            if (!validationContactMagFormEmail (el.find("#clientMail"))) {
                return false;
            }
        });

        el.find("#numTelephone").blur(function() {
            if (!validationContactMagFormTel (el.find("#numTelephone"))) {
                return false;
            }
        });

        el.find("#clientMessage").blur(function() {
            if (!validationContactMagFormCompulsory (el.find("#clientMessage"))) {
                return false;
            }
        })

        el.find("#wrapper_optin").change(function() {
            if (!validationContactMagFormOption (el.find("#wrapper_optin"), el.find("#optin_y"), el.find("#optin_n"))) {
                return false;
            }
        } );

        el.find("#wrapper_optin_sms").change(function() {
            if (!validationContactMagFormOption (el.find("#wrapper_optin_sms"), el.find("#optin_sms_y"), el.find("#optin_sms_n"))) {
                return false;
            }
        });

        el.find(".button.dark").click(function() {
            var form_valid = validationContactMagFormCompulsory (el.find("#clientNom"));

            if(!validationContactMagFormCompulsory (el.find("#clientPrenom"))) {
                form_valid = false;
            }

            if(!validationContactMagFormEmail (el.find("#clientMail"))) {
                form_valid = false;
            }

            if(!validationContactMagFormTel (el.find("#numTelephone"))) {
                form_valid = false;
            }

            if(!validationContactMagFormCompulsory (el.find("#clientMessage"))) {
                form_valid = false;
            }

            if(!validationContactMagFormOption (el.find("#wrapper_optin"), el.find("#optin_y"), el.find("#optin_n"))) {
                form_valid = false;
            }

            if(!validationContactMagFormOption (el.find("#wrapper_optin_sms"), el.find("#optin_sms_y"), el.find("#optin_sms_n"))) {
                form_valid = false;
            }

            return form_valid;
        });
    }

    if ($(".thumb.recovery_product").length) {
        $('.product_feats_wrapper li.thumb.recovery_product .thumbtitle').click(function() {
            $('.thumb.recovery_product').toggleClass('active');
        });
    };
});

$(document).on("click","#btn_top_add_wishlist", function (e) {
    e.preventDefault();
    selectedProducts = [];
    $(".remove_top_wishlist").each(function( index ){
        var id = $(this).attr("data-id");
        selectedProducts.push(id);

    });
    moveProductFromWishlistToCart(selectedProducts);
});

/*******************************************************************************************************/
/* CUSTOM FUNCTIONS
/*******************************************************************************************************/

function triggerShad(shad, action) {
    shad = shad ? ('#shad_' + shad):'#shad' ;

    if (action && action == 'add') {
        $(shad).addClass('actif');
    } else if (action) {
        $(shad).removeClass('actif');
    } else {
        return;
    }
}

// Search function to trigger form btns

function triggerFormAction(action) {
    if (action !== undefined && action !== '') {
        if ($('[data-action="'+ action +'"]')) {
            $('[data-action="'+ action +'"]').click();
        }
    }
}

// function in order to close Wshop search results and display searchPage

function closeWshopSearch() {
    if ($('.search_shad').is(':visible')) {
        $('.search_shad').fadeOut(100);
        setTimeout(function() {
            $('.search_results_wrapper').slideUp(10);
        }, 100);
    }
}

function clearWshopSearch() {
    if ($('#form_recherche #search_home').length) {
        $('#search_home').val('');
        $('.wrap_field').removeClass('search_field_active');
    }
}


// count filters in rayon
function countItemFilter(input, translation) {
    var filterName = input.name;
    var filters = $('input:checked[name='+ filterName +']');
    var countFilters = filters.length

    if (countFilters > 0) {
        if ($("#trigger_filtre [data-filtername='" + filterName + "']").length) {
            if ($("#trigger_filtre [data-filtername='" + filterName + "']").find('.count_filters').length) {
                $("#trigger_filtre [data-filtername='" + filterName + "'] .count_filters").html(' (' + countFilters + ')');
            } else {
                $("#trigger_filtre [data-filtername='" + filterName + "']").append('<span class="count_filters"> ('+ countFilters + ') </span>');
            }
        } else if ($("#trigger_filtre [data-filtername='" + translation + "']").length) {
            if ($("#trigger_filtre [data-filtername='" + translation + "']").find('.count_filters').length) {
                $("#trigger_filtre [data-filtername='" + translation + "'] .count_filters").html(' (' + countFilters + ')');
            } else {
                $("#trigger_filtre [data-filtername='" + translation + "']").append('<span class="count_filters"> ('+ countFilters + ') </span>');
            }
        }
    } else {
        if ($("#trigger_filtre .filtre_elem .count_filters").length) {
            $("#trigger_filtre .filtre_elem .count_filters").remove();
        }
    }
}

function initAchatExpressSwipers() {

    var arrayElement = ['productColorFieldset', 'productSizeFieldset'];

    for (var elementSwiper of arrayElement) {
        if ($("#list_item .ligne_form." + elementSwiper + " .choices_list .form_itm").length >= 1) {
            $('#list_item .ligne_form.' + elementSwiper).each(function(e) {
                var id = $(this).attr('id');
                new Swiper('#' + id + ' .swiper-container' , {
                    slidesPerView: 3.75,
                    spaceBetween: 16,
                    slideToClickedSlide: true,
                    navigation: {
                        nextEl: '#' + id + ' .swiper-button-next',
                        prevEl: '#' + id + ' .swiper-button-prev',
                    },
                    pagination: {
                        el: '#' + id + ' .swiper-pagination',
                        type: 'progress',
                    },
                });
            })
        }

    }
}

function closeAE() {
    var ae_blocs = $('.ae_bloc');

    if (ae_blocs.length > 1) {
        ae_blocs.each(function() {
            if ($(this).is(':visible')) {
                $(this).fadeOut(600);
            }
        });
    } else if (ae_blocs == 1) {
        if (ae_blocs.is(':visible')) {
            ae_blocs.fadeOut(600);
        }
    }
}

function build_text_summary(text_el, max_characters) {
    var text = text_el.html();

    if(text.length > max_characters) {
        //slice text to defined length
        var summary = text.slice(0, max_characters);

        //check for slice through html tag
        var position = summary.lastIndexOf('<');

        if (position) {
            var check = summary.substring(position);

            //fix slicing if in middle on html tag
            if(check !== '' && check.indexOf('>') == -1) {
                //slice text to correct length
                summary = summary.slice(0,position);

                //remove <br> at summaryning and end of text
                summary = summary.replace(/^(\s*<br\s*\/?\s*>\s*)*|(\s*<br\s*\/?\s*>\s*)*\s*$/gm, '')
            }
        }

        //fix unclosed tags
        summary = fix_unclosed_tags(summary, '...');

        return summary;
    }

    return text;
}

function fix_unclosed_tags(str, end_string) {
    var tags = ["a", "span", "div", "ul", "li", "h1", "h2", "h3", "h4", "h5", "h6", "p", "table", "tr", "td", "b", "i", "u", "strong"];
    var mismatches = [];
    var closing_tag = false;

    tags.forEach(function(tag) {
        var pattern_open = '<' + tag + '( |>)';
        var pattern_close = '</' + tag + '>';
        var diff_count = (str.match(new RegExp(pattern_open,'g')) || []).length - (str.match(new RegExp(pattern_close,'g')) || []).length;

        if(diff_count != 0) {
            closing_tag = true;
            str = str + end_string + '</' + tag + '>';
        }
    });

    if (closing_tag == false) {
        str = str + end_string;
    }

    return str;
}

function validationContactMagFormCompulsory (field) {
    if(field.val() === ''){
        field.addClass("inputErr");
        return false;
    }

    field.removeClass('inputErr');
    return true;
}

function validationContactMagFormEmail (field) {
    if(field.val() === '' || validateEmail(field.val()) === false){
        field.addClass("inputErr");
        return false;
    }

    field.removeClass('inputErr');
    return true;
}

function validationContactMagFormTel (field) {
    if ( field.val() === '' || field.intlTelInput( "isValidNumber" ) === false || field.intlTelInput( "getNumberType" ) == 0 ) {
        field.addClass("inputErr");
        return false;
    }

    field.removeClass('inputErr');
    return true;
}

function validationContactMagFormOption (field, opt1, opt2) {
    if(!opt1.is(":checked") && !opt2.is(":checked")){
        field.addClass("inputErr");
        return false;
    }

    field.removeClass("inputErr");
    return true;
}

function forceNumOnly( elmt ) {
    elmt.value = elmt.value.replace( /[^0-9. ]/g, '' ).replace( /(\..*)\./g, '$1' );
}

// Checks whether email adresss valid
function validateEmail(email) {
    var rex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

    return rex.test(email);
}

/**
 * @description on the login form, mirror the "no" radio button to the separated 'checkbox' at the bottom & observe the radio button and update the checkbox accordingly, and vice versa
 */
function mirrorOptoutCheckbox() {
    var fakeOptoutCheckbox = '#deny-optin input[type="radio"]';
    var fakeOptoutCheckboxContainer = '#deny-optin';
    var trueOptoutCheckboxSignup = '#js-signUpForm input[type="radio"][value="0"]';
    var trueOptoutCheckboxLogin = '#auth input[type="radio"][value="0"]';
    var trueOptinCheckboxSignup = '#js-signUpForm input[type="radio"][value="1"]';
    var trueOptinCheckboxLogin = '#auth input[type="radio"][value="1"]';

    // bind the event to the body, so it works even if the form is loaded after the script
    // if any radio button is changed, check if it corresponds to any of the 'checkboxes' 
    document.addEventListener('change', function (e) {
        if (e.target.matches(fakeOptoutCheckbox)) {
            try {
                document.querySelector(trueOptoutCheckboxSignup).checked = e.target.checked;
            } catch (error) {
                console.warn('Error occurred while handling trueOptoutCheckboxSignup:', error);
            }

            try {
                document.querySelector(trueOptoutCheckboxLogin).checked = e.target.checked;
            } catch (error) {
                console.warn('Error occurred while handling trueOptoutCheckboxLogin:', error);
            }
        }

        if (e.target.matches(trueOptinCheckboxSignup) || e.target.matches(trueOptinCheckboxLogin)) {
            document.querySelector(fakeOptoutCheckbox).checked = !e.target.checked;
        }
    });

    // we need to add an observer to the body, if at any point the radio button is changed, we need to make sure the user isn't stuck

    var observer = new MutationObserver(function (mutations) {
        let fakeCheckboxStatus = "neutral"; // neutral, toHide, toShow

        mutations.forEach(function (mutation) {
            // if at any point one of the true elements is removed from the dom, hide the fake checkbox
            if (mutation.removedNodes) {
                if (!document.querySelector(trueOptinCheckboxSignup) && !document.querySelector(trueOptinCheckboxLogin)) {
                    // if the true optin checkbox is removed, fade out the fake checkbox
                    fakeCheckboxStatus = "toHide";
                }
            }



            // if at any point one of the true elements is added to the dom, check its state and update the fake checkbox accordingly
            if (mutation.addedNodes) {

                if (document.querySelector(trueOptinCheckboxSignup) || document.querySelector(trueOptinCheckboxLogin)) {
                    // if the true optin checkbox is added, fade in the fake checkbox
                    fakeCheckboxStatus = "toShow";
                }


                // if the true optout checkbox is added, check its state and update the fake checkbox accordingly
                try {
                    if (document.querySelector(trueOptoutCheckboxSignup)) {
                        if (document.querySelector(trueOptoutCheckboxSignup).checked) {
                            // if the true optout checkbox is checked, check the fake checkbox
                            document.querySelector(fakeOptoutCheckbox).checked = true;
                        } else {
                            // if the true optout checkbox is unchecked, uncheck the fake checkbox
                            document.querySelector(fakeOptoutCheckbox).checked = false;
                        }
                    }
                } catch (error) {
                    console.warn('Error occurred while handling trueOptoutCheckboxSignup:', error);
                }

                try {
                    if (document.querySelector(trueOptoutCheckboxLogin)) {
                        if (document.querySelector(trueOptoutCheckboxLogin).checked) {
                            // if the true optout checkbox is checked, check the fake checkbox
                            document.querySelector(fakeOptoutCheckbox).checked = true;
                        } else {
                            // if the true optout checkbox is unchecked, uncheck the fake checkbox
                            document.querySelector(fakeOptoutCheckbox).checked = false;
                        }
                    }
                } catch (error) {
                    console.warn('Error occurred while handling trueOptoutCheckboxLogin:', error);
                }
            }
        });

        // update the fake checkbox status depending on the state of the fakeCheckboxStatus variable
        if (fakeCheckboxStatus === "toHide") {
            $(fakeOptoutCheckboxContainer).fadeOut();
        } else if (fakeCheckboxStatus === "toShow") {
            $(fakeOptoutCheckboxContainer).fadeIn();
        }
    });

    observer.observe(document.body, {
        childList: true,
        subtree: true
    });
}

window.addEventListener('DOMContentLoaded', () => {
    const pageAdvance = document.querySelector('body.cms_page_new') ? document.querySelector('body.cms_page_new') : document.querySelector('body.cms_page');
    const anchor = document.querySelector('.ancre_type');

    if (pageAdvance) {
        if (anchor) {
            setTimeout(() => {
                if (window.location.hash) {
                    const element = document.getElementById(window.location.hash.substring(1));
                    if (element) {
                        $("html, body").animate({scrollTop: $(element).offset().top - 0 }, 600);
                    }
                }
            }, 600);
        }
    }

    // we need to wait for the document to be fully loaded, so we can triiger the function mirrorOptoutCheckbox()
    mirrorOptoutCheckbox();
});